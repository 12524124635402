<template>
  <div class="landing">
    <div class="landing-section landing-section_color_grey effect-anchor">
      <Pattern
        v-if="screenSize !== 'xs'"
        fill="#E6E5E4"
        direction="horizontal"
        position="bottom"
        rows="2"
        columns="10"
      />
      <div class="landing-section__head">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2 class="h2 landing-section__title effect-unit" data-delay="1">
                Ответственное партнёрство
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="landing-section__body">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12">
              <div v-if="partnersError">Произошла ошибка при загрузке.</div>
              <div v-else class="partners effect-unit" data-delay="2">
                <PartnerItem
                  class="partners_type_archive"
                  v-for="(item, index) in partners"
                  :key="index"
                  :item="item"
                  :index="index"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Sidebar />
</template>

<script>
const API_HOST = process.env.VUE_APP_BASE_API;

import { mapMutations } from "vuex";

import { validateStatus } from "@/utils";

import Sidebar from "@/components/Sidebar.vue";
import Pattern from "@/components/Pattern.vue";
import PartnerItem from "@/components/PartnerItem.vue";

export default {
  components: {
    Sidebar,
    Pattern,
    PartnerItem
  },
  data() {
    return {
      nodes: [],
      offsets: [],
      partners: [],
      partnersError: false
    };
  },
  mounted() {
    this.update();
    this.onScroll();
    this.fetchData();
  },
  methods: {
    ...mapMutations(["setModalShow"]),
    async fetchData() {
      try {
        const response = await fetch(
          `${API_HOST}/api/public/parthner/archive/?sort=-active_till&sort=-pos`
        );
        const { status } = response;

        if (!validateStatus(status)) {
          throw new Error(`server response with status ${status}`);
        }

        this.partners = await response.json();
      } catch (error) {
        this.partnersError = true;
        console.log(error);
      }
    },
    update() {
      this.nodes = [];
      this.offsets = [];
      document.getElementsByClassName("effect-anchor").forEach(e => {
        if (!e.classList.contains("init")) {
          const top = e.getBoundingClientRect().top + window.pageYOffset;

          this.nodes.push(e);
          this.offsets.push(Math.floor(top));
        }
      });
    },
    onScroll() {
      this.offsets.forEach((e, i) => {
        this.nodes[i].classList.add("init");
        this.nodes.splice(i, 1);
        this.offsets.splice(i, 1);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.landing-section {
  min-height: 100vh;
  &__body {
    padding-top: 24px;
    @include md {
      padding-top: 50px;
    }
  }
}

.partners {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 64px 30px;
  position: relative;
  @include md {
    grid-template-columns: repeat(2, 1fr);
  }
  @include lg {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
